<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="试卷名称:">
                    <el-input placeholder="请输入关键字" v-model="filters.recordName" clearable></el-input>
                </el-form-item>
                <el-form-item label="出题类型:">
                    <el-select v-model="filters.status" clearable>
                        <el-option
                            v-for="item in questionswayArr1"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据类型:">
                        <el-select v-model="filters.dataTypes" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in optionsc"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                <el-form-item label="组织机构：" width>
                    <el-cascader
                        placeholder="请选择，支持搜索功能"
                        style="width: 100%"
                        v-model="OptionTreec"
                        :options="options"
                        :props="defaultProps"
                        filterable
                        change-on-select
                        clearable
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="是否使用:">
                    <el-select v-model="filters.keepOnFile" clearable>
                        <el-option
                            v-for="item in questionswayArr2"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <el-table
            :data="tableData"
            border
            v-loading="listLoading"
            highlight-current-row
            @current-change="handlerowChange"
            style="width: 95%;"
        >
            <el-table-column prop="PaperName" label="试卷名称" min-width="100"></el-table-column>
            <!-- <el-table-column prop="MakeQuestionType" label="出题类型" width="">
                <template scope="scope">
                    <el-tag
                        :type="!scope.row.MakeQuestionType  ? 'warning' : 'success'"
                        disable-transitions
                    >
                        {{!scope.row.MakeQuestionType ? "固定试卷":"随机试卷"}}
                    </el-tag>
                </template>
            </el-table-column> -->
            <el-table-column prop="IsRelationTrade" label="是否关联行业" align="center" min-width="120">
                <template scope="scope">
                    <el-tag
                        :type="!scope.row.IsRelationTrade  ? 'danger' : 'success'"
                        disable-transitions
                    >
                        {{!scope.row.IsRelationTrade ? "否":"是"}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="DataType" label="数据类型" min-width="100" align="center">
                <template scope="scope">
                    <el-tag
                        v-if="scope.row.DataType === 1"
                        type="warning"
                        disable-transitions
                    >岗位</el-tag>
                    <el-tag
                        v-if="scope.row.DataType === 0"
                        type="success"
                        disable-transitions
                    >行业</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="KeepOnFile" label="是否使用" min-width="100" align="center">
                <template scope="scope">
                    <el-tag
                        :type="!scope.row.KeepOnFile  ? 'warning' : 'success'"
                        disable-transitions
                    >
                        {{scope.row.KeepOnFile ? "已使用":"未使用"}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="TradeType" label="行业类型" min-width="100" align="center">
                <template scope="scope">
                    <el-tag v-if="scope.row.TradeType == 1" type="success">已分类行业</el-tag>
                    <el-tag v-else-if="scope.row.TradeType == 0" type="warning">未设置专项考试行业</el-tag>
                    <el-tag v-else type="">未知</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsEnable" label="启用标识" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-tag
                        :type="!scope.row.IsEnable  ? 'danger' : 'success'"
                        disable-transitions
                    >
                        {{!scope.row.IsEnable ? "禁用":"启用"}}
                    </el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="OrganizationInfoName" label="组织机构" width=""></el-table-column> -->
            <el-table-column prop="Score" label="试卷总分" align="center" min-width="100"></el-table-column>
            <el-table-column prop="CreateUserName" label="创建人">
                <template scope="scope">
                    <el-tag
                    :type="''"
                    disable-transitions>{{scope.row.CreateUserName}}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="20" class="pageBar">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageIndex"
            :page-sizes="pages.pageArr"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 新增 -->
        <el-dialog
            title="新增试卷"
            :visible.sync="relatedQuestion"
            v-model="relatedQuestion"
            :close-on-click-modal="false"
        >
            <el-form :model="addForm" label-width="120px" label-position="center" ref="addFormRef" :rules="addFormRules">
                <el-form-item label="试卷名称：" prop="questionsTitle">
                    <el-input v-model="addForm.questionsTitle"></el-input>
                </el-form-item>
                <!-- <el-form-item label="试卷类型：">
                    <el-select v-model="addForm.questionsType">
                        <el-option v-for="(item,index) in questionsTypeArr" :key="index" :label="item.name" :value="item.value">{{item.name}}</el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="出题方式：">
                    <el-radio-group v-model="addForm.questionsway">
                        <el-radio v-for="(item,index) in questionswayArr" :key="index" :label="item.value">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="数据类型：">
                    <el-select v-model="addForm.dataType" placeholder="请选择" @change="selectCurrentRow">
                        <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="字典信息：" prop="dictionaryType" v-if="addForm.dataType === 0 || addForm.dataType === 1">
                    <el-select v-model="addForm.dictionaryType" placeholder="请选择">
                        <el-option
                            v-for="item in dictionaryData"
                            :key="item.Id"
                            :label="item.Name"
                            :value="item.Id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行业类型：" prop="tradeType" v-if="addForm.dataType === 1 && isTradeType == 1">
                    <el-select v-model="addForm.tradeType" placeholder="请选择">
                        <el-option
                            v-for="item in tradeTypeArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="relatedQuestion = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getExaminationPaperListPage,addExaminationPaper,getOrganizationTree,deleteExaminationPaper,enableOrDisableExaminationPaper,getDataDictionaryList,getExamPaperInfoTradeTypeList } from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import router from '../../router/index';
import Qs from 'qs';
export default {
    components: { Toolbar },
    data() {
        return {
            //增删改查按钮
            buttonList: [],
            filters: {
                recordName: '',
                status: '',
                keepOnFile: '',
                dataTypes: '',
            },
            tableData: [],
            listLoading: false,
            // 新增
            relatedQuestion: false,
            addLoading: false,
            addForm: {
                questionsTitle: '',
                questionsType: 0,
                questionsway: 1,
                dataType: '',
                dictionaryType: '',
                tradeType: '',
            },
            isTradeType: 0,
            // 字典数据
            dictionaryData: [],
            questionsTypeArr: [
                {value:0,name:'取证考试',},
            ],
            questionswayArr: [
                // {value:0,name:'固定试卷'},
                {value:1,name:'随机试卷'}
            ],
            optionsc: [
                // {value:"22",name:'全部类型'},
                {value:0,name:'行业'},
                {value:1,name:'岗位'},
            ],
            options1: [
                {value:0,name:'行业'},
                {value:1,name:'岗位'},
            ],
            questionswayArr1: [
                // {value:"22",name:'所有试卷'},
                // {value:0,name:'固定试卷'},
                {value:1,name:'随机试卷'}
            ],
            questionswayArr2: [
                // {value:"22",name:'全部'},
                {value:true,name:'已使用'},
                {value:false,name:'未使用'}
            ],
            tradeTypeArr: [
                {value: 1,name: '已分类行业'},
                {value: 0,name:'未设置专项考试行业'}
            ],
            addFormRules:{
                questionsTitle:[{required:true,message:'请输入试卷名称',trigger:'blur'}],
                questionsType:[{required:true,message:'请选择试卷类型',trigger:'blur'}],
                dictionaryType:[{required:true,message:'请选择字典信息',trigger:'blur'}],
                tradeType:[{required:true,message:'请选择行业类型',trigger:'blur'}],
            },
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            tableClickData:'',
            // 组织结构树数据
            defaultProps: {
                children: "Children",
                label: "Name",
                value:'ID' //可要可不要
            },
            OptionTreec: [],
            options: []
        }
    },
    methods: {
        // 增，删，改，查
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // table事件
        handlerowChange(val) {
            this.tableClickData = val
        },
        // 查询
        getExaminationPaperListPage() {
            this.pages.pageIndex = 1
            this.getPageList();
        },
        // 新增
        handleAdd() {
            this.relatedQuestion = true
            this.addForm = {
                questionsTitle: '',
                questionsType: 0,
                questionsway: 1,
                dataType: '',
                dictionaryType: '',
            }
        },
        // select选中值发生变化
        selectCurrentRow(val) {
            this.addForm.dictionaryType = ''
            var params = {
                dataType: val
            }
            this.getListData(params);
        },
        // 获取字典数据分页列表
        getListData(params) {
            getDataDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.dictionaryData = result.Response
                }
            })
        },
        // 获取行业类型
        getTradeTypeData() {
            var params = {}
            getExamPaperInfoTradeTypeList(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.tradeTypeArr = result.Response
                }
            })
        },
        // 编辑
        handleEdit() {
            if(this.tableClickData){
                // return
                sessionStorage.setItem("needData",JSON.stringify(this.tableClickData))
                if(this.tableClickData.MakeQuestionType == 0){
                    router.replace({
                        path: "/fixedTestPaper",
                        query: {
                            redirect: router.currentRoute.fullPath,
                            titel:this.tableClickData.PaperName,
                            dataType: this.tableClickData.DataType,
                            dictionaryType: this.tableClickData.DataDictionaryId,
                            tradeType: (this.addForm.dataType === 1 && this.isTradeType == 1) ? this.tableClickData.TradeType : null,
                            paperType:true,
                            time:new Date()
                        }
                    });
                }else if(this.tableClickData.MakeQuestionType == 1){
                    router.replace({
                        path: "/fixedTestPaper",
                        query: {
                            redirect: router.currentRoute.fullPath,
                            titel:this.tableClickData.PaperName,
                            dataType: this.tableClickData.DataType,
                            dictionaryType: this.tableClickData.DataDictionaryId,
                            tradeType: (this.addForm.dataType === 1 && this.isTradeType == 1) ? this.tableClickData.TradeType : null,
                            paperType:false,
                            time:new Date()
                        }
                    });
                }
            }else{
                this.$message({
                    message: '请选择要编辑数据！',
                    type: 'warning'
                });
                return
            }
        },
        // 删除
        handleDel() {
            if(this.tableClickData){
                this.$confirm('确定删除？',"提示",{
                }).then(() => {
                    var params = {
                        ID: this.tableClickData.ID
                    }
                    this.deletePaper(params);
                }).catch(() => {
                    
                })
            }else{
                this.$message({
                    message: '请选择要删除数据！',
                    type: 'warning'
                });
                return
            }
        },
        deletePaper(params) {
            deleteExaminationPaper(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getPageList();
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                }
            })
        },
        // 启用/禁用
        handleEnableOrDisable() {
            if(this.tableClickData){
                this.$confirm('确定启用/禁用？',"提示",{
                }).then(() => {
                    var params = {
                        ID: this.tableClickData.ID
                    }
                    this.enablePaper(params);
                }).catch(() => {
                    
                })
            }else{
                this.$message({
                    message: '请选择要启用/禁用数据！',
                    type: 'warning'
                });
                return
            }
        },
        // 获取树结构数据
        getOrganizations() {
            var _this = this
            var ID = JSON.parse(localStorage.getItem("user")).ID
            var params = {
                parentId: 0
            }
            getOrganizationTree(params).then(res => {
                var data = res.data
                var resData = [data.Response]
                if(data.Success){
                if(data.Response.Name == "根节点"){
                    _this.options = data.Response.Children
                }else{
                    _this.options = resData
                }
                }
            })
        },
        enablePaper(params) {
            enableOrDisableExaminationPaper(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getPageList();
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                }
            })
        },
        // 关联考试项目
        relationExamProjectInfo() {

        },
        // 保存
        addSubmit() {
            this.$refs['addFormRef'].validate((valid) => {
                if(valid){
                    if(this.addForm.questionsway ===  0){
                        this.relatedQuestion = false;
                        var params = {}
                        params.PaperName = this.addForm.questionsTitle
                        params.MakeQuestionType = this.addForm.questionsway
                        params.ExamPaperTMList = []
                        // this.addPaper(params);
                        router.replace({
                            path: "/fixedTestPaper",
                            query: {
                                redirect: router.currentRoute.fullPath,
                                titel:this.addForm.questionsTitle,
                                dataType:this.addForm.dataType,
                                dictionaryType:this.addForm.dictionaryType,
                                tradeType: (this.addForm.dataType === 1 && this.isTradeType == 1) ? this.addForm.tradeType : null,
                                paperType:true,
                                time:new Date()
                            }
                        });
                    }
                    if(this.addForm.questionsway ===  1){
                        this.relatedQuestion = false;
                        var params = {}
                        params.PaperName = this.addForm.questionsTitle
                        params.MakeQuestionType = this.addForm.questionsway
                        params.ExamPaperTMList = []
                        // this.addPaper(params);
                        router.replace({
                            path: "/fixedTestPaper",
                            query: {
                                redirect: router.currentRoute.fullPath,
                                titel:this.addForm.questionsTitle,
                                dataType:this.addForm.dataType,
                                dictionaryType:this.addForm.dictionaryType,
                                tradeType: (this.addForm.dataType === 1 && this.isTradeType == 1) ? this.addForm.tradeType : null,
                                paperType:false,
                                time:new Date()
                            }
                        });
                    }
                }
            })
        },
        // 新增接口
        addPaper(params) {
            addExaminationPaper(params).then((res) => {
                var result = res.data
                if(result.Success){
                    // this.$message({
                    //     message: result.Message,
                    //     type: 'success'
                    // });
                    // this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
                    if(this.addForm.questionsway ===  0){
                        router.replace({
                            path: "/fixedTestPaper",
                            query: {redirect: router.currentRoute.fullPath,titel:this.addForm.questionsTitle,ID:result.Response,tradeType: (this.addForm.dataType === 1 && this.isTradeType == 1) ? this.addForm.tradeType : null,paperType:true,time:new Date()}
                        });
                    }
                    if(this.addForm.questionsway ===  1){
                        router.replace({
                            path: "/fixedTestPaper",
                            query: {redirect: router.currentRoute.fullPath,titel:this.addForm.questionsTitle,ID:result.Response,tradeType: (this.addForm.dataType === 1 && this.isTradeType == 1) ? this.addForm.tradeType : null,paperType:false,time:new Date()}
                        });
                    }
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                }
            })
        },
        getPageList(){
            this.listLoading = true;
            var params = {
                pageIndex : this.pages.pageIndex,
                pageSize : this.pages.pageSize,
            }
            if(this.filters.recordName){
                params.paperName = this.filters.recordName
            }
            if(this.filters.status != '22'){
                params.makeQuestionType = this.filters.status
            }
            if(this.filters.keepOnFile != '22'){
                params.keepOnFile = this.filters.keepOnFile
            }
            if(this.filters.dataTypes != '22'){
                params.dataType = this.filters.dataTypes
            }
            if(this.OptionTreec.length > 0){
                // params.organizationInfoID = this.OptionTreec.pop()
                params.organizationInfoID = this.OptionTreec[this.OptionTreec.length - 1]
            }
            getExaminationPaperListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    var needData = result.Response.Data
                    needData.forEach(item => {
                        item.ExamPaperTMList.forEach(o => {
                            if(item.MakeQuestionType == 0){
                                o.ExamPaperEQList.forEach(i => {
                                    i.teachID = o.TeachingMaterialInfoID
                                });
                            }
                        });
                    });
                    this.tableData = needData
                    // this.tableData = result.Response.Data
                    this.listLoading = false;
                }else{
                    var _this = this
                    setTimeout(() => {
                        _this.listLoading = false;
                    },2000)
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getPageList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val
            this.getPageList();
        },
    },
    watch: {
        'addForm.dictionaryType': function(val) {
            this.dictionaryData.forEach(item => {
                if(item.ID == val) {
                    if(item.IsRelation) {
                        this.isTradeType = 1
                    } else {
                        this.isTradeType = 0
                    }
                }
            })
        },
    },
    mounted() {
        this.getPageList();
        this.getOrganizations();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        sessionStorage.removeItem("needData")
    }
}
</script>

<style lang="stylus" scoped>
.pageBar{
	height :60px;
}
</style>